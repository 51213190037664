/** @jsx jsx */
import { useLocation } from "@reach/router"
import { navigate } from "gatsby"
import { Fragment, useCallback, useEffect } from "react"

import { useMutation, userClient } from "@trueskin-web/apis"
import { ALink, ClientOnly, Container, Spinner } from "@trueskin-web/components"
import { jsx } from "@trueskin-web/theme"
import { Trans, useTranslation } from "@trueskin-web/translations"

import Layout from "../components/layout"
import Seo from "../components/seo"

const isProduction = process.env.GATSBY_DEPLOY_ENVIRONMENT === "production"

const RateResponse = () => {
  const { search } = useLocation()

  const searchParams = new URLSearchParams(search)
  const customer = searchParams.get("customer")
  const email = searchParams.get("email")
  const platform = searchParams.get("platform")

  const {
    mutateAsync: getReviewLinkAsync,
    isLoading,
    isError,
  } = useMutation(userClient.getReviewLink)

  const getReviewLink = useCallback(
    async (payload) => {
      try {
        const { url } = await getReviewLinkAsync(payload)

        if (!isProduction) {
          window.alert(
            "THIS IS NOT PRODUCTION WEBSITE. PLEASE DO NOT SUBMIT YOUR REVIEW!"
          )
        }

        window.location.replace(url)
        return null
      } catch (err) {}
    },
    [getReviewLinkAsync]
  )

  useEffect(() => {
    const payload = {
      customer,
      email,
      platform,
    }

    window && getReviewLink(payload)
  }, [customer, email, platform, getReviewLink])

  if (!searchParams || !customer || !email || !platform) {
    navigate("/")
    return null
  }

  return isLoading ? (
    <Fragment>
      <Spinner />
      <p>
        <Trans i18nKey="RateUs.message" />
      </p>
    </Fragment>
  ) : isError ? (
    <p>
      <Trans i18nKey="RateUs.error">
        <ALink
          href="mailto:support@formelskin.de"
          target="_blank"
          rel="noopener noreferrer"
        />
      </Trans>
    </p>
  ) : null
}

const RateUsPage = () => {
  const { t } = useTranslation()

  return (
    <Layout>
      <Seo title={t("RateUs.title")} />

      <Container sx={{ pt: 10, textAlign: "center" }}>
        <ClientOnly>
          <RateResponse />
        </ClientOnly>
      </Container>
    </Layout>
  )
}

export default RateUsPage
